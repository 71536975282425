<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <TagCategoryForm :data="empty" @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {SuccessNotification} from "@/domain/core/exception/exceptions";
import TagCategoryForm from "@/domain/tag/components/TagCategoryForm.vue";

export default {
  name: 'NewTag',
  components: {TagCategoryForm},
  data() {
    return {
      empty: {
        code: '',
        description: '',
      }
    }
  },
  methods: {
    ...mapActions('tag', [
      'createCategory',
    ]),
    async onSave(data) {
      await this.createCategory(data)
      await this.$router.push({name: 'tag_category.list'})
      throw new SuccessNotification(this.$pgettext('tag', 'Tag Category created!'))
    }
  }
}
</script>

<style scoped>

</style>