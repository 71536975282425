import { render, staticRenderFns } from "./NewCategoryTag.vue?vue&type=template&id=67358b1a&scoped=true&"
import script from "./NewCategoryTag.vue?vue&type=script&lang=js&"
export * from "./NewCategoryTag.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67358b1a",
  null
  
)

export default component.exports