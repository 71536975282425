<template>
  <CCard>
    <CCardHeader>
      <div class="card-header-actions">
        <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                 translate-context="form" class="mr-2">Reset
        </CButton>
        <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
      </div>
    </CCardHeader>
    <CCardBody class="p-0">
      <CContainer fluid class="grid-stripped">
        <PFormRow>
          <template v-slot:firstColContent>
            {{ $pgettext('tag.label', 'Code') }}
          </template>
          <template v-slot:secondColContent>
            <CInput
                v-model="form.code"
                ref="form-code"
                :placeholder="$pgettext('tag.placeholder','Code')"
            />
          </template>
        </PFormRow>
        <PFormRow>
          <template v-slot:firstColContent>
            {{ $pgettext('tag.label', 'Description') }}
          </template>
          <template v-slot:secondColContent>
            <CTextarea
                v-model="form.description"
                ref="form-description"
            />
          </template>
        </PFormRow>
      </CContainer>
    </CCardBody>
  </CCard>

</template>

<script>
import PFormRow from "@/components/PFormRow.vue";

export default {
  name: "TagCategoryForm",
  components: {PFormRow},
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        code: '',
        description: '',
      },
    }
  },
  mounted() {
    this.onReset()
  },
  emits: ['save'],
  methods: {
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      this.$emit('save', this.form)
    },
  }
}
</script>

<style scoped>

</style>